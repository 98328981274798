<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<div>
			<CInput
				v-model.trim="searchTerm"
				placeholder="Search by store"
				class="flex-fill"
				autocomplete="off"
				data-test-id="input-search"
			>
				<template #prepend-content>
					<CIcon class="cil-magnifying-glass" name="cil-magnifying-glass" />
				</template>
			</CInput>
		</div>
		<slot
			v-if="!filteredItems.length"
			name="no-item"
		>
		</slot>
		<div v-show="filteredItems.length" class="list">
			<div class="select-all mb-1 align-items-center">
				<BaseIndeterminateCheckbox
					:checked="isEveryChecked()"
					:indeterminate="isIndeterminate()"
					label="Select all"
					class="d-inline-block"
					@update:checked="handleSelectAll"
				/>
			</div>
			<DynamicScroller
				:items="filteredItems"
				:min-item-size="35"
				key-field="id"
				class="scroller list-select"
			>
				<template v-slot="{ item, index, active }">
					<DynamicScrollerItem
						:item="item"
						:active="active"
						:data-index="index"
						class="list-item"
					>
						<div class="d-flex flex-fill">
							<CInputCheckbox
								:id="`${uid}_${item.id}`"
								:checked.sync="item.selected"
								class="d-inline-block list-checkbox overflow-hidden"
								custom
							>
								<template #label>
									<label :for="`${uid}_${item.id}`" class="custom-control-label list-label w-100 typo-body-2 font-weight-normal text-nowrap text-truncate color-black-45 mb-0 ml-2">
										<span class="label color-black">{{ item.com7BranchId }}</span>
										<span class="label ml-2 color-black-45">{{ `${item.nameTH} | ${item.provinceTH}` }}</span>
									</label>
								</template>
							</CInputCheckbox>
						</div>
					</DynamicScrollerItem>
				</template>
			</DynamicScroller>
		</div>
	</div>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
import isEmpty from 'lodash/isEmpty';
import { mapActions, mapGetters } from 'vuex';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
	name: 'FormGetStore',
	components: {
		DynamicScroller,
		DynamicScrollerItem,
	},
	props: {
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			uid: null,
			isLoading: true,
			searchTerm: '',
			localStoreList: [],
		};
	},
	computed: {
		...mapGetters({
			warehouseList: 'warehouses/warehouseList',
		}),
		filteredItems() {
			if (isEmpty(this.localStoreList)) {
				return [];
			}

			const term = this.searchTerm.toLowerCase();
			const isIncludeId = (item) => `${item.com7BranchId}`.includes(term);
			const isIncludeName = (item) => `${item.nameTH} ${item.nameEN} ${item.provinceTH}`.toLowerCase().includes(term);
			return this.localStoreList.filter((item) => isIncludeId(item) || isIncludeName(item));
		},
	},
	watch: {
		localStoreList: {
			immediate: false,
			deep: true,
			handler(value) {
				if (!value || value.length <= 0) {
					return;
				}

				const selectedItems = value.filter(({ selected }) => selected);
				this.$emit('onChange', selectedItems);
			},
		},
	},
	mounted() {
		this.uid = uniqueId('store_checkbox_');
	},
	async created() {
		await this.getWarehouseList({ per_page: 'all' });
		this.localStoreList = this.getStoreWithSelectedStatus(this.warehouseList);
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getWarehouseList: 'warehouses/getWarehouseList',
		}),
		resetState() {
			this.searchTerm = '';
			this.localStoreList = this.getStoreWithSelectedStatus(this.warehouseList);
		},
		getStoreWithSelectedStatus(storeList = []) {
			return storeList.map((store) => {
				const selected = !!this.selectedList.find(({ id }) => id === store.id);
				return {
					...store,
					selected,
				};
			});
		},
		getCheckedState() {
			if (!this.filteredItems || this.filteredItems.length <= 0) {
				return [];
			}

			const state = this.filteredItems.reduce((acc, current) => {
				const selected = current.selected;
				return [
					...acc,
					selected,
				];
			}, []);

			return state;
		},
		isIndeterminate() {
			const state = this.getCheckedState();
			return state.some((bool) => bool) && !state.every((bool) => bool);
		},
		isEveryChecked() {
			const state = this.getCheckedState();
			return state.every((bool) => bool);
		},
		handleSelectAll(checked) {
			if (!this.filteredItems) {
				return;
			}

			// Select all or Deselect all only filterd items
			this.filteredItems.forEach((store) => {
				const localStore = this.localStoreList.find(({ id }) => id === store.id);
				if (localStore) {
					localStore.selected = checked;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
	.scroller {
		height: 100%;
	}

	.btn-add {
		min-width: rem(84);
	}

	.list {
		margin: 0 -#{rem(16)} -#{rem(16)};
	}

	.select-all {
		background-color: $color-gray-100;
		padding: rem(8) rem(16);
	}

	.list-select {
		min-height: rem(360);
		max-height: rem(360);
		list-style: none;
		padding: 0;
		margin: 0;

		.list-item {
			padding: rem(6) rem(16);

			label {
				margin-top: rem(3);
				cursor: pointer;
			}
		}
	}
</style>
