<template>
	<li class="border rounded-sm border-secondary mb-3">
		<div class="item-link typo-body-2 d-flex px-3 py-4 text-decoration-none">
			<div class="item-info typo-body-2 color-black-45">
				{{ skuList }}
			</div>
			<div class="item-actions">
				<span class="item-fee">{{ formatFee }}</span>
				<router-link
					:to="{
						name: 'ShippingProductAdditionalFeeEdit',
						params: {
							shippingMethodId: data.shippingMethodId,
							feeId: data.id
						}
					}"
					class="icon-edit"
				>
					<CIcon name="cil-pencil" />
				</router-link>
			</div>
		</div>
	</li>
</template>

<script>
import { priceFormat } from '../assets/js/helpers';

export default {
	name: 'ShippingRateListItem',

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		shippingMethodId: {
			type: Number,
			default: null,
		},
	},
	computed: {
		formatFee() {
			return priceFormat(this.data.fee);
		},
		skuList() {
			return this.data.items.map((item) => item.sku).join('; ');
		},
	},
};
</script>

<style lang="scss" scoped>
	.item {
		// .item-info
		&-info {
			flex: 2.6;
		}

		// .item-actions
		&-actions {
			flex: 1;
			text-align: right;

			.icon-edit {
				color: $color-gray-500;
				margin-left: rem(40);

				&:hover {
					color: $color-orange;
				}
			}
		}
	}
</style>
