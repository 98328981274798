<template>
	<div>
		<h4 class="my-5">
			General settings
		</h4>
		<CRow class="form-group">
			<CCol>
				<div class="label">
					Status
				</div>
			</CCol>
			<CCol md="3">
				<div class="form-group-switch">
					<CSwitch
						id="status"
						:checked.sync="generalSetting.isActive"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span class="typo-body-2">
						{{ generalSetting.isActive ? 'Active' : 'Inactive' }}
					</span>
				</div>
			</CCol>
		</CRow>
		<CRow class="form-group">
			<CCol>
				<div class="label">
					Specify delivery time
				</div>
			</CCol>
			<CCol md="3">
				<div class="form-group-switch">
					<CSwitch
						id="specify-delivery-time"
						:checked.sync="generalSetting.isSpecifyDeliveryTimeActive"
						variant="3d"
						size="sm"
						class="switch-custom"
						color="success"
					/>
					<span class="typo-body-2">
						{{ generalSetting.isSpecifyDeliveryTimeActive ? 'On' : '24/7' }}
					</span>
				</div>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<div
					v-if="generalSetting.isSpecifyDeliveryTimeActive"
					:class="[
						'business-hour-wrapper p-4 mb-4 mx-0 rounded-sm',
						{ 'invalid': $v.generalSetting.deliveryTime.$error }
					]"
				>
					<BusinessHour
						ref="business-hour"
						:business-hours.sync="generalSetting.businessHours"
						title="Delivery"
					/>
				</div>
				<div
					v-if="$v.generalSetting.deliveryTime.$error"
					class="typo-error-text"
				>
					Required field
				</div>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Maximum weight*
				</div>
				<div class="description typo-helper-text color-black-45">
					per order
				</div>
			</CCol>
			<CCol md="3">
				<BaseInputNumber
					v-model="$v.generalSetting.maxWeight.$model"
					:is-valid="!$v.generalSetting.maxWeight.$error"
					:invalid-feedback="showErrors($v.generalSetting.maxWeight)"
					:decimal-scale="2"
					text-align="left"
					append-text="kg"
				/>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			class="form-group"
		>
			<CCol md="4">
				<div class="label mt-2">
					Maximum product size*
				</div>
				<div class="description typo-helper-text color-black-45">
					largest product in the cart
				</div>
			</CCol>
			<CCol
				md="8"
				class="d-flex"
			>
				<BaseInputNumber
					v-model="$v.generalSetting.maxLength.$model"
					:is-valid="!$v.generalSetting.maxLength.$error"
					:invalid-feedback="showErrors($v.generalSetting.maxLength)"
					:decimal-scale="2"
					class="input-product-size mr-2 ml-auto"
					upper-hint="Length*"
					text-align="left"
					append-text="cm"
				/>
				<BaseInputNumber
					v-model="$v.generalSetting.maxWidth.$model"
					:is-valid="!$v.generalSetting.maxWidth.$error"
					:invalid-feedback="showErrors($v.generalSetting.maxWidth)"
					:decimal-scale="2"
					class="input-product-size mr-2"
					upper-hint="Width*"
					text-align="left"
					append-text="cm"
				/>
				<BaseInputNumber
					v-model="$v.generalSetting.maxHeight.$model"
					:is-valid="!$v.generalSetting.maxHeight.$error"
					:invalid-feedback="showErrors($v.generalSetting.maxHeight)"
					:decimal-scale="2"
					class="input-product-size"
					upper-hint="Height*"
					text-align="left"
					append-text="cm"
				/>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Maximum quantity*
				</div>
				<div class="description typo-helper-text color-black-45">
					per order
				</div>
			</CCol>
			<CCol md="3">
				<BaseInputNumber
					v-model="$v.generalSetting.maxQuantity.$model"
					:is-valid="!$v.generalSetting.maxQuantity.$error"
					:invalid-feedback="showErrors($v.generalSetting.maxQuantity)"
					append-text="item"
				/>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Minimum purchase amount*
				</div>
				<div class="description typo-helper-text color-black-45">
					per order
				</div>
			</CCol>
			<CCol md="3">
				<BaseInputNumber
					v-model="$v.generalSetting.minPurchaseAmount.$model"
					:is-valid="!$v.generalSetting.minPurchaseAmount.$error"
					:invalid-feedback="showErrors($v.generalSetting.minPurchaseAmount)"
					:decimal-scale="2"
					prepend-text="฿"
					text-align="right"
				/>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Maximum purchase amount*
				</div>
				<div class="description typo-helper-text color-black-45">
					per order
				</div>
			</CCol>
			<CCol md="3">
				<BaseInputNumber
					v-model="$v.generalSetting.maxPurchaseAmount.$model"
					:is-valid="!$v.generalSetting.maxPurchaseAmount.$error"
					:invalid-feedback="showErrors($v.generalSetting.maxPurchaseAmount)"
					:decimal-scale="2"
					prepend-text="฿"
					text-align="right"
				/>
			</CCol>
		</CRow>
		<CRow
			v-if="SHIPPING_METHOD.EXPRESS === shippingMethodId"
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Available for areas
				</div>
			</CCol>
			<CCol md="3" class="d-flex justify-content-end">
				<CButton
					class="transparent"
					color="secondary"
					@click="handleEditAreas"
				>
					Edit areas
				</CButton>
			</CCol>
		</CRow>
		<CRow
			v-if="SHIPPING_METHOD.PICKUP_AT_STORE === shippingMethodId"
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Available for stores
				</div>
			</CCol>
			<CCol md="3" class="d-flex justify-content-end">
				<CButton
					class="transparent"
					color="secondary"
					@click="handleEditAvailableStore"
				>
					Edit stores
				</CButton>
			</CCol>
		</CRow>
		<CRow
			v-if="isAllowToAddStore"
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Available for stores
				</div>
			</CCol>
			<CCol md="3" class="d-flex justify-content-end">
				<CButton
					class="transparent"
					color="secondary"
					@click="handleEditStore"
				>
					Edit stores
				</CButton>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Available for categories
				</div>
			</CCol>
			<CCol md="3" class="d-flex justify-content-end">
				<CButton
					class="transparent"
					color="secondary"
					@click="handleEditCategory"
				>
					Edit categories
				</CButton>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Exclude SKU
				</div>
			</CCol>
			<CCol md="3" class="d-flex justify-content-end">
				<CButton
					class="transparent"
					color="secondary"
					@click="$refs['modal-sku'].open()"
				>
					Edit SKU
				</CButton>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			align-vertical="center"
			class="form-group"
		>
			<CCol>
				<div class="label">
					Exclude customer group
				</div>
			</CCol>
			<CCol md="4" class="d-flex justify-content-end">
				<CButton
					class="transparent"
					color="secondary"
					@click="$refs['modal-customer-group'].open()"
				>
					Edit customer group
				</CButton>
			</CCol>
		</CRow>
		<CRow
			:gutters="false"
			class="form-group mb-0"
		>
			<CCol>
				<div class="label mt-2">
					Estimated delivery time (hr)
				</div>
			</CCol>
			<CCol class="d-flex justify-content-end">
				<BaseInputNumber
					v-model="generalSetting.estimatedDeliveryHoursFrom"
					class="input-delivery"
					lower-hint="optional"
					text-align="left"
					append-text="hr"
				/>
				<div class="typo-body-2 mt-2 mx-3">
					to
				</div>
				<BaseInputNumber
					v-model="$v.generalSetting.estimatedDeliveryHoursTo.$model"
					:is-valid="!$v.generalSetting.estimatedDeliveryHoursTo.$error"
					:invalid-feedback="errorEstimatedDeliveryTime"
					class="input-delivery"
					text-align="left"
					append-text="hr"
				/>
			</CCol>
			<CCol lg="12">
				<CRow class="mt-4">
					<CCol lg="12">
						<label>Delivery instruction (Thai)</label>
						<BaseRichTextEditor
							v-model="generalSetting.termsTh"
							name="termsTh"
						/>
					</CCol>
				</CRow>
				<CRow class="mt-2">
					<CCol lg="12">
						<label>Delivery instruction (English)</label>
						<BaseRichTextEditor
							v-model="generalSetting.termsEn"
							name="termsEn"
						/>
					</CCol>
				</CRow>
				<CRow class="form-group mt-4">
					<CCol lg="12">
						<ShippingPaymentMethodSetting
							:payment-method-settings.sync="generalSetting.paymentMethodSettings"
						/>
					</CCol>
				</CRow>
				<CRow class="form-group mt-4">
					<CCol class="text-right">
						<BaseSaveButton
							:disabled="edit.isUpdating || isLoadingShippingPaymentMethods"
							@click="handleSubmit"
						/>
					</CCol>
				</CRow>
			</CCol>
		</CRow>
		<ModalSkuList
			ref="modal-sku"
			title="Exclude SKUs"
			:sku-list="generalSetting.excludedProducts"
			@onConfirm="handleExcludeSkuChange"
		/>
		<ModalAvailableStore
			v-if="SHIPPING_METHOD.PICKUP_AT_STORE === shippingMethodId"
			ref="modal-available-store"
			:selected-list="generalSetting.branches"
			@onSubmit="handleSubmitAvailableStore"
			@onClose="handleCloseStore"
		/>
		<ModalPostcode
			v-if="SHIPPING_METHOD.EXPRESS === shippingMethodId"
			ref="modal-postcode"
			:list="generalSetting.postcodes"
			@onConfirm="handleSubmitAreas"
		/>
		<ModalCustomerGroup
			v-if="isCurrentTab"
			ref="modal-customer-group"
			title="Edit exclude customer group"
			:list="generalSetting.excludedCustomerGroups"
			@onConfirm="handleExcludedCustomerGroupsChange"
		/>
		<ModalStore
			v-if="isAllowToAddStore"
			ref="modal-store"
			:selected-list="generalSetting.branches"
			data-test-id="modal-store"
			@onConfirm="handleSubmitStore"
		/>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { required, requiredIf, maxValue } from 'vuelidate/lib/validators';
import ModalSkuList from '@/components/ModalSkuList.vue';
import ModalAvailableStore from '@/components/ModalAvailableStore.vue';
import ModalStore from '@/components/ModalStore.vue';
import ModalPostcode from '@/components/ModalPostcode.vue';
import ModalCustomerGroup from '@/components/ModalCustomerGroup.vue';
import BusinessHour from '@/components/BusinessHour.vue';
import ShippingPaymentMethodSetting from '@/components/ShippingPaymentMethodSetting.vue';
import { numberFormat, priceToAPI, priceToNumber, scrollToTop, convertTimeToUTC } from '../assets/js/helpers';
import { DEFAULT_BUSINESS_HOURS } from '../enums/general';
import { SHIPPING_METHOD } from '../enums/shippings';

export default {
	name: 'ShippingGeneralSetting',
	validations: {
		generalSetting: {
			deliveryTime: {
				required: requiredIf((vm) => {
					if (!vm.isSpecifyDeliveryTimeActive) {
						return false;
					}

					return !vm.businessHours.some((businessHour) => businessHour.isActive);
				}),
			},
			maxWeight: {
				required,
				maxValue: maxValue(999999999),
			},
			maxLength: {
				required,
				maxValue: maxValue(999999999),
			},
			maxWidth: {
				required,
				maxValue: maxValue(999999999),
			},
			maxHeight: {
				required,
				maxValue: maxValue(999999999),
			},
			maxQuantity: {
				required,
				maxValue: maxValue(999999999),
			},
			minPurchaseAmount: {
				required,
				maxValue: maxValue(9999999999),
			},
			maxPurchaseAmount: {
				required,
				maxValue: maxValue(9999999999),
				greaterThanMinAmount: (value, vm) => {
					return priceToNumber(vm.maxPurchaseAmount) > priceToNumber(vm.minPurchaseAmount);
				},
			},
			estimatedDeliveryHoursTo: {
				required,
				greaterThanHourFrom: (value, vm) => {
					return Number(vm.estimatedDeliveryHoursTo) > Number(vm.estimatedDeliveryHoursFrom);
				},
			},
		},
	},
	components: {
		ModalSkuList,
		ModalAvailableStore,
		ModalStore,
		ModalPostcode,
		ModalCustomerGroup,
		BusinessHour,
		ShippingPaymentMethodSetting,
	},
	props: {
		shippingMethodId: {
			type: Number,
			default: null,
		},
		isActive: {
			type: Boolean,
			default: false,
		},
		isSpecifyDeliveryTimeActive: {
			type: Boolean,
			default: false,
		},
		branches: {
			type: Array,
			default: () => [],
		},
		paymentMethodSettings: {
			type: Array,
			default: () => [],
		},
		businessHours: {
			type: Array,
			default: () => [],
		},
		categories: {
			type: Array,
			default: () => [],
		},
		maxWeight: {
			type: [String, Number],
			default: null,
		},
		maxLength: {
			type: [String, Number],
			default: null,
		},
		maxWidth: {
			type: [String, Number],
			default: null,
		},
		maxHeight: {
			type: [String, Number],
			default: null,
		},
		estimatedDeliveryHoursFrom: {
			type: [String, Number],
			default: null,
		},
		estimatedDeliveryHoursTo: {
			type: [String, Number],
			default: null,
		},
		maxQuantity: {
			type: [String, Number],
			default: null,
		},
		minPurchaseAmount: {
			type: [String, Number],
			default: null,
		},
		maxPurchaseAmount: {
			type: [String, Number],
			default: null,
		},
		termsTh: {
			type: String,
			default: null,
		},
		termsEn: {
			type: String,
			default: null,
		},
		excludedProducts: {
			type: Array,
			default: () => [],
		},
		excludedCustomerGroups: {
			type: Array,
			default: () => [],
		},
		postcodes: {
			type: Array,
			default: () => [],
		},
		isCurrentTab: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			generalSetting: {
				isActive: false,
				isSpecifyDeliveryTimeActive: false,
				businessHours: [],
				deliveryTimeFrom: null,
				deliveryTimeTo: null,
				maxWeight: null,
				maxLength: null,
				maxWidth: null,
				maxHeight: null,
				estimatedDeliveryHoursFrom: null,
				estimatedDeliveryHoursTo: null,
				maxQuantity: null,
				minPurchaseAmount: null,
				maxPurchaseAmount: null,
				termsTh: null,
				termsEn: null,
				excludedProducts: [],
				excludedCustomerGroups: [],
				branches: [],
				postcodes: [],
				paymentMethodSettings: [],
			},
			SHIPPING_METHOD,
			allowToAddStoreShipmentIds: [
				SHIPPING_METHOD.PICKUP_AND_PAY,
				SHIPPING_METHOD.COLLECT_IN_ONE_HOUR,
			],
		};
	},
	computed: {
		...mapState('shippings', {
			edit: (state) => state.shippingMethods.edit,
			isLoadingShippingPaymentMethods: (state) => state.shippingPaymentMethods.isLoading,
		}),
		...mapGetters({
			getSelectedCategories: 'categorySelector/getSelectedCategories',
		}),
		errorDeliveryTimeFrom() {
			if (!this.$v.generalSetting.deliveryTimeFrom.required) {
				return 'Required field';
			}

			if (!this.$v.generalSetting.deliveryTimeFrom.validTime) {
				return 'Time not valid';
			}

			return null;
		},
		errorDeliveryTimeTo() {
			if (!this.$v.generalSetting.deliveryTimeTo.required) {
				return 'Required field';
			}

			if (!this.$v.generalSetting.deliveryTimeTo.validTime) {
				return 'Time not valid';
			}

			if (!this.$v.generalSetting.deliveryTimeTo.after) {
				return `Should be after ${this.deliveryTimeFrom} hr`;
			}

			return null;
		},
		errorEstimatedDeliveryTime() {
			if (!this.$v.generalSetting.estimatedDeliveryHoursTo.required) {
				return 'Required field';
			}

			if (!this.$v.generalSetting.estimatedDeliveryHoursTo.greaterThanHourFrom) {
				return `Should be after ${this.generalSetting.estimatedDeliveryHoursFrom}`;
			}

			return null;
		},
		categoryIds() {
			return this.getSelectedCategories.map((category) => category.id);
		},
		isAllowToAddStore() {
			return this.allowToAddStoreShipmentIds.includes(this.shippingMethodId);
		},
	},
	created() {
		this.generalSetting = {
			isActive: this.isActive,
			isSpecifyDeliveryTimeActive: this.isSpecifyDeliveryTimeActive,
			// Merge default business hours with business hour from API
			businessHours: DEFAULT_BUSINESS_HOURS.reduce((acc, businessHour) => {
				return [
					...acc,
					{
						...businessHour,
						...this.businessHours.find((item) => item.day === businessHour.day),
					},
				];
			}, []),
			branches: this.branches,
			deliveryTimeFrom: this.businessHours.deliveryFrom,
			deliveryTimeTo: this.businessHours.deliveryTo,
			maxWeight: this.maxWeight,
			maxLength: this.maxLength,
			maxWidth: this.maxWidth,
			maxHeight: this.maxHeight,
			estimatedDeliveryHoursFrom: this.estimatedDeliveryHoursFrom,
			estimatedDeliveryHoursTo: this.estimatedDeliveryHoursTo,
			maxQuantity: this.maxQuantity,
			minPurchaseAmount: this.minPurchaseAmount,
			maxPurchaseAmount: this.maxPurchaseAmount,
			postcodes: this.postcodes,
			termsTh: this.termsTh,
			termsEn: this.termsEn,
			excludedProducts: this.excludedProducts.map((item) => item.sku),
			excludedCustomerGroups: this.excludedCustomerGroups,
			paymentMethodSettings: this.paymentMethodSettings,
		};
	},
	methods: {
		...mapActions({
			updateShippingMethod: 'shippings/updateShippingMethod',
			getShippingMethodList: 'shippings/getShippingMethodList',
		}),
		showErrors(v) {
			if (!v.required) {
				return 'Required field';
			}
			if (!v.maxValue) {
				return `Maximum value is ${numberFormat(v.$params.maxValue.max)}`;
			}

			if (!v.greaterThanMinAmount) {
				return `Max purchase amount should be more than ${this.generalSetting.minPurchaseAmount}`;
			}

			return null;
		},
		async handleSubmit() {
			const refBusinessHour = this.$refs['business-hour'];
			this.$v.$touch();

			if (refBusinessHour) {
				refBusinessHour.$v.$touch();
			}

			if (this.$v.$invalid || (refBusinessHour && refBusinessHour.$v.$invalid)) {
				scrollToTop();
				return;
			}

			const params = {
				is_active: this.generalSetting.isActive ? 1 : 0,
				is_specify_delivery_time_active: this.generalSetting.isSpecifyDeliveryTimeActive ? 1 : 0,
				business_hours: this.generalSetting.businessHours.map((businessHour) => {
					let from;
					let to;

					if (businessHour.isActive) {
						from = businessHour.from ? convertTimeToUTC(businessHour.from) : null;
						to = businessHour.to ? convertTimeToUTC(businessHour.to) : null;
					} else {
						from = null;
						to = null;
					}

					return {
						day: businessHour.day,
						hour: { from, to },
						is_active: !!businessHour.isActive,
					};
				}),
				min_order: priceToAPI(this.generalSetting.minPurchaseAmount),
				max_order: priceToAPI(this.generalSetting.maxPurchaseAmount),
				max_weight: Number(this.generalSetting.maxWeight).toFixed(2),
				max_length: Number(this.generalSetting.maxLength).toFixed(2),
				max_width: Number(this.generalSetting.maxWidth).toFixed(2),
				max_height: Number(this.generalSetting.maxHeight).toFixed(2),
				max_quantity: Number(this.generalSetting.maxQuantity),
				estimated_delivery_hours_from: Number(this.generalSetting.estimatedDeliveryHoursFrom),
				estimated_delivery_hours_to: Number(this.generalSetting.estimatedDeliveryHoursTo),
				terms_and_conditions_th: this.generalSetting.termsTh || '',
				terms_and_conditions_en: this.generalSetting.termsEn || '',
				areas: [],
				category_ids: this.categoryIds,
				excluded_product_skus: this.generalSetting.excludedProducts,
				excluded_customer_groups: this.generalSetting.excludedCustomerGroups.map((group) => group.id),
				rates: [],
				branches: this.branches.map((branch) => ({
					branch_id: branch.id,
					is_standard: branch.isStandard,
					is_express: branch.isExpress,
				})),
				postcode_ids: this.generalSetting.postcodes,
				payment_method_setting: this.generalSetting.paymentMethodSettings,
			};

			await this.updateShippingMethod({
				id: this.shippingMethodId,
				params,
			});

			await this.getShippingMethodList();

			scrollToTop();
		},
		handleEditCategory() {
			this.$emit('onEditCategories');
		},
		handleEditAvailableStore() {
			this.$refs['modal-available-store'].open();
		},
		handleSubmitAvailableStore(branches = []) {
			this.$emit('update:branches', branches);
		},
		handleCloseStore() {
			this.$refs['modal-available-store'].setStores(this.branches);
		},
		handleEditAreas() {
			this.$refs['modal-postcode'].open();
		},
		handleSubmitAreas(postcodes = []) {
			this.generalSetting.postcodes = postcodes;
		},
		handleExcludeSkuChange(skus = []) {
			this.generalSetting.excludedProducts = skus;
		},
		handleExcludedCustomerGroupsChange(groups = []) {
			this.generalSetting.excludedCustomerGroups = groups;
		},
		handleEditStore() {
			this.$refs['modal-store'].open();
		},
		handleSubmitStore(branches = []) {
			const branchesTransformed = branches.map((item) => {
				return {
					...item,
					isExpress: 1,
					isStandard: 1,
				};
			});

			this.$emit('update:branches', branchesTransformed);
		},
	},
};
</script>

<style lang="scss" scoped>
	.form-group {
		margin-bottom: rem(32);
	}

	.input-delivery {
		width: rem(90);
	}

	.input-product-size {
		width: rem(120);
	}

	.time-table, .business-hour-wrapper {
		background-color: $color-gray-100;
		border: rem(1) solid $color-gray-100;

		&.invalid {
			border: rem(1) solid $color-alert;
		}
	}
</style>
