<template>
	<li class="rate-item mb-3">
		<div class="rate-item-link">
			<div class="rate-item-info">
				<span v-if="rate.isDefault" class="rate-item-default">Default</span>
				<div v-else class="rate-item-details">
					<div class="rate-item-detail">
						<span class="rate-item-label">Price:</span> <span class="rate-item-value">{{ rate.minPrice }} – {{ rate.maxPrice }}</span>
					</div>
					<div class="rate-item-detail">
						<span class="rate-item-label">Weight:</span> <span class="rate-item-value">{{ rate.minWeight }}kg – {{ rate.maxWeight }}kg</span>
					</div>
					<div class="rate-item-detail">
						<span class="rate-item-label">Quantity:</span> <span class="rate-item-value">{{ rate.minQuantity }} – {{ rate.maxQuantity }}</span>
					</div>
				</div>
			</div>
			<div class="rate-item-actions">
				<span class="rate-item-fee">{{ rate.fee }}</span>
				<router-link
					:to="{
						name: 'ShippingRateEdit',
						params: {
							shippingMethodId: rate.shippingMethodId,
							rateId: rate.id
						}
					}"
					class="icon-edit-rate cil-pencil"
				>
					<CIcon name="cil-pencil" />
				</router-link>
			</div>
		</div>
	</li>
</template>

<script>
import { numberFormat, priceFormat } from '../assets/js/helpers';

export default {
	name: 'ShippingRateListItem',

	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		rate() {
			return {
				...this.data,
				fee: priceFormat(this.data.fee),
				minPrice: priceFormat(this.data.minPrice),
				maxPrice: priceFormat(this.data.maxPrice),
				minQuantity: numberFormat(this.data.minQuantity),
				maxQuantity: numberFormat(this.data.maxQuantity),
				minWeight: numberFormat(this.data.minWeight),
				maxWeight: numberFormat(this.data.maxWeight),
			};
		},
	},
};
</script>

<style lang="scss" scoped>
	.rate-item {
		border-radius: 4px;
		border: 1px solid $color-gray-400;

		// .rate-item-link
		&-link {
			@include typo-body-2;

			display: flex;
			padding: rem(20) rem(16);
			text-decoration: none;
		}

		// .rate-item-info
		&-info {
			flex: 2.6;
		}

		// .rate-item-actions
		&-actions {
			flex: 1;
			text-align: right;

			.icon-edit-rate {
				color: $color-gray-500;
				margin-left: rem(40);

				&:hover {
					color: $color-orange;
				}
			}
		}

		// .rate-item-detail
		&-detail {
			margin-bottom: rem(8);

			&:last-child {
				margin-bottom: 0;
			}
		}

		// .rate-item-label
		&-label {
			display: inline-block;
			width: rem(78);
		}

		// .rate-item-value
		&-value {
			font-weight: 600;
		}
	}
</style>
