<template>
	<li class="border rounded-sm border-secondary mb-3">
		<div class="item-link typo-body-2 d-flex px-3 pt-4 pb-2 text-decoration-none">
			<div class="item-info">
				<div
					v-for="item in data.items"
					:key="item.id"
					class="list-item"
				>
					{{ item.id }}
				</div>
			</div>
			<div class="item-actions">
				<span class="item-fee">{{ formatFee }}</span>
				<router-link
					:to="{
						name: 'ShippingPostcodeAdditionalFeeEdit',
						params: {
							shippingMethodId: data.shippingMethodId,
							feeId: data.id
						}
					}"
					class="icon-edit"
				>
					<CIcon name="cil-pencil" />
				</router-link>
			</div>
		</div>
	</li>
</template>

<script>
import { priceFormat, formattedCategories } from '../assets/js/helpers';

export default {
	name: 'PostcodeAdditionalFeeListItem',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		shippingMethodId: {
			type: Number,
			default: null,
		},
	},
	computed: {
		formatFee() {
			return priceFormat(this.data.fee);
		},
	},
	methods: {
		formattedCategories,
	},
};
</script>


<style lang="scss" scoped>
	.item {
		// .item-info
		&-info {
			flex: 2.6;

			.list-item {
				border-radius: rem(4);
				background-color: $color-gray-300;
				color: $color-black-90;
				display: inline-block;
				padding: 0 rem(6);
				margin-right: rem(12);
				margin-bottom: rem(12);
			}
		}

		// .item-actions
		&-actions {
			flex: 1;
			text-align: right;

			.icon-edit {
				color: $color-gray-500;
				margin-left: rem(40);

				&:hover {
					color: $color-orange;
				}
			}
		}
	}
</style>
