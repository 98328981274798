<template>
	<div>
		<div class="d-flex mb-4">
			<div>
				<div class="label mr-5">
					{{ title }} days*
				</div>
			</div>
			<div>
				<div class="label ml-2">
					{{ title }} hours*
				</div>
			</div>
		</div>
		<div
			v-for="(vBusinessHour, index) in $v.businessHours.$each.$iter"
			:key="index"
			class="d-flex align-items-top"
		>
			<CInputCheckbox
				:checked.sync="vBusinessHour.isActive.$model"
				:label="capitalize(vBusinessHour.day.$model)"
				class="working-day-checkbox mt-1"
				custom
			/>
			<BaseInputTime
				v-model="vBusinessHour.from.$model"
				:is-valid="!vBusinessHour.from.$error"
				:disabled="!vBusinessHour.isActive.$model"
				:invalid-feedback="fromErrors(vBusinessHour.from)"
			/>
			<div class="mx-3 mt-1">
				to
			</div>
			<BaseInputTime
				v-model="vBusinessHour.to.$model"
				:is-valid="!vBusinessHour.to.$error"
				:disabled="!vBusinessHour.isActive.$model"
				:invalid-feedback="toErrors(vBusinessHour.to, index)"
			/>
		</div>
	</div>
</template>

<script>
import { requiredIf, or } from 'vuelidate/lib/validators';
import { capitalize } from '../assets/js/helpers';
import { after, timeFormat } from '../assets/js/validators';
import { DEFAULT_BUSINESS_HOURS } from '../enums/general';

export default {
	name: 'BusinessHour',
	validations: {
		businessHours: {
			$each: {
				day: {},
				isActive: {},
				from: {
					required: requiredIf((vm) => vm.isActive),
					format: (value, vm) => {
						if (!vm.isActive) {
							return true;
						}
						return timeFormat(value);
					},
				},
				to: {
					required: requiredIf((vm) => vm.isActive),
					format: (value, vm) => {
						if (!vm.isActive) {
							return true;
						}
						return timeFormat(value);
					},
					after: or(
						(day, vm) => !vm.isActive,
						after('from', 'to'),
					),
				},
			},
		},
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		businessHours: {
			type: Array,
			default: () => DEFAULT_BUSINESS_HOURS,
		},
	},
	methods: {
		fromErrors(obj) {
			if (!obj.required) {
				return 'Required field';
			}

			if (!obj.format) {
				return `Time format is invalid`;
			}

			return null;
		},
		toErrors(obj, index) {
			if (!obj.required) {
				return 'Required field';
			}

			if (!obj.after) {
				return `Should be after ${this.businessHours[index].from}`;
			}

			if (!obj.format) {
				return `Time format is invalid`;
			}

			return null;
		},
		capitalize(text) {
			return capitalize(text);
		},
	},
};
</script>


<style lang="scss" scoped>
	.working-day-checkbox {
		width: rem(140);
		height: rem(54);
	}
</style>
