<template>
	<CModal
		:show.sync="isShow"
		:close-on-backdrop="false"
		centered
	>
		<template #header-wrapper>
			<ModalHeaderWrapper
				title="Available for stores"
				@onClose="handleClose"
			/>
		</template>
		<template #default class="p-0">
			<CInput
				v-model.trim="searchTerm"
				placeholder="Search by branch ID, branch name"
				class="flex-fill"
				autocomplete="off"
			>
				<template #prepend-content>
					<CIcon class="cil-magnifying-glass" name="cil-magnifying-glass" />
				</template>
			</CInput>
			<div v-if="filteredItems.length">
				<CRow class="mb-2">
					<CCol md="8">
						<span class="label color-black-45">Stores</span>
					</CCol>
					<CCol md="2" class="d-flex justify-content-center">
						<span class="label color-black-45">Standard</span>
					</CCol>
					<CCol md="2" class="d-flex justify-content-center">
						<span class="label color-black-45">Express</span>
					</CCol>
				</CRow>
				<CRow class="header py-2">
					<CCol md="8">
						<span class="label">Select all</span>
					</CCol>
					<CCol md="2" class="d-flex justify-content-center">
						<CInputCheckbox
							id="standard-all"
							ref="standard-all"
							:checked.sync="isSelectAll.standard"
							custom
							@update:checked="handleCheckedAll('standard')"
						/>
					</CCol>
					<CCol md="2" class="d-flex justify-content-center">
						<CInputCheckbox
							id="express-all"
							ref="express-all"
							:checked.sync="isSelectAll.express"
							custom
							@update:checked="handleCheckedAll('express')"
						/>
					</CCol>
				</CRow>
				<DynamicScroller
					:items="filteredItems"
					:min-item-size="36"
					key-field="id"
					class="scroller list-select"
				>
					<template v-slot="{ item, index, active }">
						<DynamicScrollerItem
							:item="item"
							:active="active"
							:data-index="index"
							class="list-item"
						>
							<CRow>
								<CCol md="1">
									<span class="label">{{ item.branchId }}</span>
								</CCol>
								<CCol md="7">
									<span class="label color-black-45">{{ item.name }}</span>
								</CCol>
								<CCol md="2" class="d-flex justify-content-center">
									<CInputCheckbox
										:id="`standard-${item.id}`"
										:checked="checkItemIsSelected(item.id, 'standard')"
										custom
										@update:checked="handleCheckedWarehouse($event, item.id, 'standard')"
									/>
								</CCol>
								<CCol md="2" class="d-flex justify-content-center">
									<CInputCheckbox
										:id="`express-${item.id}`"
										:checked="checkItemIsSelected(item.id, 'express')"
										custom
										@update:checked="handleCheckedWarehouse($event, item.id, 'express')"
									/>
								</CCol>
							</CRow>
						</DynamicScrollerItem>
					</template>
				</DynamicScroller>
			</div>
			<template v-else>
				<div
					class="text-center mt-5 mb-5"
					data-test-id="no-item"
				>
					<div class="typo-body-1 font-weight-bolder color-black-45">
						Search not found
					</div>
					<div class="typo-body-2 color-black-45">
						We didn't find any stores matching your criteria
					</div>
				</div>
			</template>
		</template>
		<template #footer>
			<BaseModalFooter
				:total-select="totalSelected"
				@onCancel="handleClose"
				@onConfirm="handleSubmit"
			/>
		</template>
	</CModal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';

export default {
	name: 'ModalAvailableStore',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		DynamicScroller,
		DynamicScrollerItem,
	},
	props: {
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			searchTerm: '',
			isShow: false,
			isSelectAll: {
				standard: false,
				express: false,
			},
			selected: {
				standard: [],
				express: [],
			},
		};
	},
	computed: {
		...mapGetters({
			warehouseList: 'warehouses/warehouseList',
		}),
		items() {
			return this.warehouseList.map((warehouse) => ({
				id: warehouse.id,
				branchId: warehouse.com7BranchId,
				name: `${warehouse.nameTH} | ${warehouse.provinceTH}`,
				standard: false,
				express: false,
			}));
		},
		filteredItems() {
			const term = this.searchTerm.toLowerCase();
			const isIncludeId = (item) => item.branchId.toString().includes(term);
			const isIncludeName = (item) => item.name.toLowerCase().includes(term);
			return this.items.filter((item) => isIncludeId(item) || isIncludeName(item));
		},
		totalSelected() {
			const standardIds = this.selected.standard;
			const expressIds = this.selected.express;

			return [...new Set([...standardIds, ...expressIds])].length;
		},
	},
	watch: {
		filteredItems() {
			const elemStandard = this.$el.querySelector('#standard-all');
			const elemExpress = this.$el.querySelector('#express-all');
			this.checkSelectionState(this.filteredItems, this.selected.standard, elemStandard);
			this.checkSelectionState(this.filteredItems, this.selected.express, elemExpress);
		},
		'selected.standard': {
			deep: true,
			handler(selected) {
				const elem = this.$el.querySelector('#standard-all');
				this.checkSelectionState(this.filteredItems, selected, elem);
			},
		},
		'selected.express': {
			deep: true,
			handler(selected) {
				const elem = this.$el.querySelector('#express-all');
				this.checkSelectionState(this.filteredItems, selected, elem);
			},
		},
		selectedList: {
			deep: true,
			immediate: true,
			handler(list) {
				this.setStores(list);
			},
		},
	},
	async created() {
		await this.getWarehouseList({
			per_page: 'all',
		});
	},
	methods: {
		...mapActions({
			getWarehouseList: 'warehouses/getWarehouseList',
		}),
		open() {
			this.isShow = true;
		},
		close() {
			this.isShow = false;
			this.searchTerm = '';
		},
		setStores(list = []) {
			// Reset selected list
			this.selected = {
				standard: [],
				express: [],
			};

			const standardIds = list.filter((item) => item.isStandard === 1).map((item) => item.id);
			const expressIds = list.filter((item) => item.isExpress === 1).map((item) => item.id);
			const totalItems = this.filteredItems.length;

			// Select "All" checkbox for standard
			if (standardIds.length === totalItems) {
				this.isSelectAll.standard = true;
			}
			// Select "All" checkbox for express
			if (expressIds.length === totalItems) {
				this.isSelectAll.express = true;
			}

			this.addToSelectedList(standardIds, 'standard');
			this.addToSelectedList(expressIds, 'express');
		},
		handleClose() {
			this.$emit('onClose');
			this.close();
		},
		handleSubmit() {
			const standardStoreIds = this.selected.standard;
			const expressStoreIds = this.selected.express;
			const intersectStoreIds = standardStoreIds.filter((value) => expressStoreIds.includes(value));

			const standardStore = standardStoreIds
				.filter((value) => !intersectStoreIds.includes(value))
				.map((item) => ({
					id: item,
					isStandard: 1,
					isExpress: 0,
				}));

			const intersectStore = intersectStoreIds.map((item) => ({
				id: item,
				isStandard: 1,
				isExpress: 1,
			}));

			this.$emit('onSubmit', [...standardStore, ...intersectStore]);
			this.close();
		},
		handleCheckedWarehouse(event, warehouseId, type) {
			const isSelected = this.checkItemIsSelected(warehouseId, type);

			if (!isSelected) {
				this.addToSelectedList(warehouseId, type);
			} else {
				this.removeFromSelectedList(warehouseId, type);
			}
		},
		checkItemIsSelected(val, type) {
			return this.selected[type].some((item) => item === val);
		},
		/**
		 * @param {String|Array} items Warehouse or List of warehouses
		 */
		addToSelectedList(items = [], type) {
			let newItems = items;
			if (!Array.isArray(items)) {
				newItems = [items];
			}

			// Auto select standard type when user select express type
			if (type === 'express') {
				this.addToSelectedList(newItems, 'standard');
			}

			this.selected[type] = [...new Set([...this.selected[type], ...newItems])].sort();
		},

		/**
		 * @param {String|Array} items Warehouse or List of warehouses
		 */
		removeFromSelectedList(items = [], type) {
			let itemsToRemove = items;
			if (!Array.isArray(items)) {
				itemsToRemove = [items];
			}

			// When user unselect standard, it will auto unselect express as well
			if (type === 'standard') {
				this.removeFromSelectedList(itemsToRemove, 'express');
			}

			this.selected[type] = this.selected[type].filter((selectedItem) => !itemsToRemove.includes(selectedItem));
		},
		checkSelectionState(items, selected, elem) {
			const isEverySelected = items.length && items.every((warehouse) => selected.includes(warehouse.id));
			const isSomeSelected = items.length && items.some((warehouse) => selected.includes(warehouse.id));

			if (isEverySelected) {
				this.setSelectedAllState(elem, 'checked');
				return;
			}

			if (isSomeSelected) {
				this.setSelectedAllState(elem, 'indeterminated');
				return;
			}

			this.setSelectedAllState(elem, 'unchecked');
		},
		handleCheckedAll(type) {
			const selectedItems = this.filteredItems.map((item) => item.id);

			if (this.isSelectAll[type]) {
				this.addToSelectedList(selectedItems, type);
			} else {
				this.removeFromSelectedList(selectedItems, type);
			}
		},
		setSelectedAllState(elem, state) {
			let isChecked = false;
			let isIndeterminate = false;
			switch (state) {
				case 'checked': {
					isChecked = true;
					isIndeterminate = false;
					break;
				}
				case 'unchecked': {
					isChecked = false;
					isIndeterminate = false;
					break;
				}
				case 'indeterminated': {
					isChecked = true;
					isIndeterminate = true;
					break;
				}
				default: break;
			}

			if (elem) {
				elem.checked = isChecked;
				elem.indeterminate = isIndeterminate;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
	.header {
		background-color: $color-gray-100;
	}

	.list-container {
		min-height: rem(360);
		max-height: rem(360);
	}

	.list {
		margin: 0 -#{rem(16)} -#{rem(16)};
	}

	.select-all {
		background-color: $color-gray-100;
		padding: rem(8) rem(16);
	}

	.list-select {
		min-height: rem(360);
		max-height: rem(360);
		list-style: none;
		padding: 0;
		margin: 0;

		.list-item {
			padding: rem(6) 0;

			label {
				margin-top: rem(3);
				cursor: pointer;
			}
		}
	}

	::v-deep .vue-recycle-scroller {
		margin-left: rem(-16);
		margin-right: rem(-16);

		.vue-recycle-scroller__item-view {
			padding-left: rem(16);
			padding-right: rem(16);
		}
	}
</style>
