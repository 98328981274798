<template>
	<li class="border rounded-sm border-secondary mb-3">
		<div class="item-link typo-body-2 d-flex px-3 py-4 text-decoration-none">
			<div class="item-info">
				<div
					v-for="category in categories"
					:key="category.id"
				>
					{{ category.text }}
				</div>
			</div>
			<div class="item-actions">
				<span class="item-fee">{{ formatFee }}</span>
				<router-link
					:to="{
						name: 'ShippingCategoryAdditionalFeeEdit',
						params: {
							shippingMethodId: data.shippingMethodId,
							categoryAdditionalFeeId: data.id
						}
					}"
					class="icon-edit"
				>
					<CIcon name="cil-pencil" />
				</router-link>
			</div>
		</div>
	</li>
</template>

<script>
import { priceFormat, formattedCategories } from '../assets/js/helpers';

export default {
	name: 'CategoryAdditionalFeeListItem',
	props: {
		data: {
			type: Object,
			default: () => ({}),
		},
		shippingMethodId: {
			type: Number,
			default: null,
		},
	},
	computed: {
		formatFee() {
			return priceFormat(this.data.fee);
		},
		categories() {
			if (Array.isArray(this.data?.items) && this.data.items.length > 0) {
				return this.data.items.map((category) => ({
					id: category.id,
					text: formattedCategories(category, 'name_en'),
				}));
			}
			return [];
		},
	},
	methods: {
		formattedCategories,
	},
};
</script>


<style lang="scss" scoped>
	.item {
		// .item-info
		&-info {
			flex: 2.6;
		}

		// .item-actions
		&-actions {
			flex: 1;
			text-align: right;

			.icon-edit {
				color: $color-gray-500;
				margin-left: rem(40);

				&:hover {
					color: $color-orange;
				}
			}
		}
	}
</style>
