<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<div v-else class="tabs-container">
		<div class="tabs-wrapper">
			<CTabs
				:active-tab="activeTab"
				@update:activeTab="handleUpdateTab"
			>
				<CTab
					v-for="(shippingMethod, index) in shippingMethodList"
					:key="shippingMethod.id"
					:title="shippingMethod.name"
				>
					<div v-if="activeTab === index" class="main-wrapper">
						<ShippingGeneralSetting
							:ref="`general-setting-${index}`"
							:is-current-tab="currentShippingMethodId === shippingMethod.id"
							:shipping-method-id="shippingMethod.id"
							:is-active="shippingMethod.isActive"
							:is-specify-delivery-time-active="shippingMethod.isSpecifyDeliveryTimeActive"
							:branches.sync="shippingMethod.branches"
							:business-hours="shippingMethod.businessHours"
							:max-weight="shippingMethod.maxWeight"
							:max-length="shippingMethod.maxLength"
							:max-width="shippingMethod.maxWidth"
							:max-height="shippingMethod.maxHeight"
							:max-quantity="shippingMethod.maxQuantity"
							:min-purchase-amount="shippingMethod.minOrder"
							:max-purchase-amount="shippingMethod.maxOrder"
							:postcodes="shippingMethod.postcodes"
							:estimated-delivery-hours-from="shippingMethod.estimatedDeliveryHoursFrom"
							:estimated-delivery-hours-to="shippingMethod.estimatedDeliveryHoursTo"
							:terms-th="shippingMethod.termsAndConditionsTH"
							:terms-en="shippingMethod.termsAndConditionsEN"
							:excluded-products="shippingMethod.excludedProducts"
							:excluded-customer-groups="shippingMethod.excludedCustomerGroups"
							:payment-method-settings="shippingMethod.paymentMethodSettings"
							@onEditCategories="handleEditCategories"
						/>
						<FeeList
							:shipping-method-id="shippingMethod.id"
							title="Base shipping fee"
							header-name="Rules"
							fee-name="Shipping fee"
							route-name="ShippingRateCreate"
							:creatable="!pickupAndPayMethod"
						>
							<BaseLoading v-if="shippingRateList.isLoading" />
							<template v-else>
								<ShippingRateListItem
									v-for="rate in shippingRateList.data"
									:key="rate.id"
									:data="rate"
								/>
							</template>
						</FeeList>
						<FeeList
							v-if="!pickupAndPayMethod"
							:shipping-method-id="shippingMethod.id"
							title="Categories additional fee (on top)"
							header-name="Categories"
							fee-name="Additional fee"
							route-name="ShippingCategoryAdditionalFeeCreate"
						>
							<BaseLoading v-if="categoryAdditionalFeeList.isLoading" />
							<template v-else>
								<CategoryAdditionalFeeListItem
									v-for="categoryAdditionalFee in categoryAdditionalFeeList.data"
									:key="categoryAdditionalFee.id"
									:data="categoryAdditionalFee"
								/>
								<BaseNoItemBanner
									v-if="!categoryAdditionalFeeList.data.length"
									text="There are no categories additional fee"
								/>
							</template>
						</FeeList>
						<FeeList
							v-if="!pickupAndPayMethod"
							:shipping-method-id="shippingMethod.id"
							title="SKUs additional fee (on top)"
							header-name="SKUs"
							fee-name="Additional fee"
							route-name="ShippingProductAdditionalFeeCreate"
						>
							<BaseLoading v-if="productAdditionalFeeList.isLoading" />
							<template v-else>
								<ProductAdditionalFeeListItem
									v-for="productAdditionalFee in productAdditionalFeeList.data"
									:key="productAdditionalFee.id"
									:data="productAdditionalFee"
								/>
								<BaseNoItemBanner
									v-if="!productAdditionalFeeList.data.length"
									text="There are no SKUs additional fee"
								/>
							</template>
						</FeeList>
						<FeeList
							v-if="!pickupAndPayMethod"
							:shipping-method-id="shippingMethod.id"
							title="Postcodes additional fee (on top)"
							route-name="ShippingPostcodeAdditionalFeeCreate"
						>
							<BaseLoading v-if="postcodeAdditionalFeeList.isLoading" />
							<template v-else>
								<PostcodeAdditionalFeeListItem
									v-for="postcodeAdditionalFee in postcodeAdditionalFeeList.data"
									:key="postcodeAdditionalFee.id"
									:data="postcodeAdditionalFee"
								/>
								<BaseNoItemBanner
									v-if="!postcodeAdditionalFeeList.data.length"
									text="There are no postcode for this additional fee"
								/>
							</template>
						</FeeList>
					</div>
				</CTab>
			</CTabs>
		</div>
		<ModalCategory
			ref="modal-category"
			title="Available for categories"
			:selected-ids="selectedIds"
			@onSubmit="handleSubmit"
			@onCancel="handleCancelCategoryModal"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ShippingGeneralSetting from '@/components/ShippingGeneralSetting.vue';
import FeeList from '@/components/FeeList.vue';
import ShippingRateListItem from '@/components/ShippingRateListItem.vue';
import ProductAdditionalFeeListItem from '@/components/ProductAdditionalFeeListItem.vue';
import CategoryAdditionalFeeListItem from '@/components/CategoryAdditionalFeeListItem.vue';
import PostcodeAdditionalFeeListItem from '@/components/PostcodeAdditionalFeeListItem.vue';
import ModalCategory from '@/components/ModalCategory.vue';

import { SHIPPING_ADDITIONAL_FEE_TYPE, SHIPPING_METHOD } from '../enums/shippings';

export default {
	components: {
		ShippingGeneralSetting,
		FeeList,
		ShippingRateListItem,
		ProductAdditionalFeeListItem,
		CategoryAdditionalFeeListItem,
		PostcodeAdditionalFeeListItem,
		ModalCategory,
	},
	data() {
		return {
			isLoading: false,
			localCategories: [],
			SHIPPING_METHOD,
		};
	},
	computed: {
		...mapGetters({
			activeTab: 'shippings/activeTab',
			shippingMethodList: 'shippings/shippingMethodList',
			getCurrentShippingMethod: 'shippings/getCurrentShippingMethod',
			shippingRatesByShippingMethodId: 'shippings/shippingRatesByShippingMethodId',
			additionalFeesByShippingMethodId: 'additionalFees/additionalFeesByShippingMethodId',
		}),
		selectedIds() {
			return this.localCategories.map((category) => category.id);
		},
		currentShippingMethodId() {
			return this.getCurrentShippingMethod.id;
		},
		shippingRateList() {
			return this.shippingRatesByShippingMethodId(this.currentShippingMethodId);
		},
		categoryAdditionalFeeList() {
			return this.additionalFeesByShippingMethodId(this.currentShippingMethodId, SHIPPING_ADDITIONAL_FEE_TYPE.CATEGORY);
		},
		productAdditionalFeeList() {
			return this.additionalFeesByShippingMethodId(this.currentShippingMethodId, SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT);
		},
		postcodeAdditionalFeeList() {
			return this.additionalFeesByShippingMethodId(this.currentShippingMethodId, SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE);
		},
		pickupAndPayMethod() {
			return this.currentShippingMethodId === SHIPPING_METHOD.PICKUP_AND_PAY;
		},
	},
	async created() {
		this.isLoading = true;
		await this.getShippingMethodList();
		this.isLoading = false;
		this.getShippingRateAndAdditionalList();
		this.localCategories = this.getCurrentShippingMethod.categories;
	},
	methods: {
		...mapActions({
			getShippingMethodList: 'shippings/getShippingMethodList',
			getShippingRateList: 'shippings/getShippingRateList',
			setShippingActiveTab: 'shippings/setShippingActiveTab',
			getShippingAdditionalByType: 'additionalFees/getShippingAdditionalByType',
			preSelectCategories: 'categorySelector/preSelectCategories',
		}),
		async getShippingRateAndAdditionalList() {
			await Promise.all([
				this.getShippingRateList(),
				this.getShippingAdditionalByType({ id: this.currentShippingMethodId, type: SHIPPING_ADDITIONAL_FEE_TYPE.CATEGORY }),
				this.getShippingAdditionalByType({ id: this.currentShippingMethodId, type: SHIPPING_ADDITIONAL_FEE_TYPE.PRODUCT }),
				this.getShippingAdditionalByType({ id: this.currentShippingMethodId, type: SHIPPING_ADDITIONAL_FEE_TYPE.POSTCODE }),
			]);
		},
		handleSubmit(value) {
			this.localCategories = value;
		},
		handleUpdateTab(tabIndex) {
			this.setShippingActiveTab(tabIndex);

			this.localCategories = this.getCurrentShippingMethod.categories;
			this.preSelectCategories(this.selectedIds);

			this.getShippingRateAndAdditionalList();
		},
		handleEditCategories() {
			this.$refs['modal-category'].open();
		},
		handleCancelCategoryModal() {
			// Reset category data when user close/cancel category modal
			this.preSelectCategories(this.selectedIds);
		},
	},
};
</script>

