<template>
	<CRow>
		<CCol lg="12">
			<hr class="my-5">
		</CCol>
		<CCol lg="9">
			<h4>{{ title }}</h4>
		</CCol>
		<CCol
			v-if="creatable"
			lg="3"
			class="text-right"
		>
			<router-link
				:to="{
					name: routeName,
					params: { shippingMethodId }
				}"
				class="btn btn-secondary"
			>
				<CIcon class="mr-2" name="cil-plus" /> Create
			</router-link>
		</CCol>
		<CCol lg="12">
			<header class="d-flex mt-4 mb-3">
				<span class="header-rules label">{{ headerName }}</span>
				<span class="header-shipping-fee label">{{ feeName }}</span>
			</header>
			<ul class="list-unstyled p-0 ">
				<slot name="default"></slot>
			</ul>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'FeeList',
	props: {
		title: {
			type: String,
			default: null,
		},
		headerName: {
			type: String,
			default: null,
		},
		feeName: {
			type: String,
			default: null,
		},
		routeName: {
			type: String,
			default: null,
		},
		shippingMethodId: {
			type: [String, Number],
			default: null,
		},
		creatable: {
			type: Boolean,
			default: true,
		},
	},
};
</script>

<style lang="scss" scoped>
	.header {
		// .header-rules
		&-rules {
			flex: 3;
		}

		// .header-shipping-fee
		&-shipping-fee {
			flex: 1;
		}
	}
</style>
