<template>
	<BaseLoading v-if="isLoading" />
	<div v-else>
		<h5 class="typo-body-1">{{ title }}</h5>
		<div class="py-2 px-3">
			<div class="label">Method</div>
		</div>
		<div class="mb-5">
			<div class="border border-gray-400 rounded pb-3">
				<div class="bg-gray-100">
					<CRow class="py-2">
						<CCol>
							<div class="label ml-3">
								Select all
							</div>
						</CCol>
						<CCol md="2" class="text-center">
							<BaseIndeterminateCheckbox
								:checked="isEveryCheckboxChecked('isSelected', localPaymentMethodList)"
								:indeterminate="isIndeterminateCheckbox('isSelected', localPaymentMethodList)"
								class="d-inline-block"
								@update:checked="handleCheckAll($event)"
							/>
						</CCol>
					</CRow>
				</div>
				<div
					v-for="(group) in localPaymentMethodList"
					:key="group.id"
					class="typo-label py-2"
				>
					<CRow>
						<CCol>
							<div class="color-black-45 font-weight-normal ml-4">
								<span class="color-gray-900">{{ group.name }}</span>
							</div>
						</CCol>
						<template v-if="!group.methods || group.methods.length <= 1">
							<CCol
								md="2"
								class="text-center"
							>
								<CInputCheckbox
									:checked.sync="group.isSelected"
									class="d-inline-block"
									custom
								/>
							</CCol>
						</template>
						<CCol v-else md="12" class="mt-1">
							<CRow
								v-for="(method) in group.methods"
								:key="method.id"
								class="mt-2 mb-2"
							>
								<CCol>
									<div class="ml-3 color-black-45 font-weight-normal ml-5">
										{{ method.name }}
									</div>
								</CCol>
								<CCol
									md="2"
									class="text-center"
								>
									<CInputCheckbox
										:checked.sync="method.isSelected"
										class="d-inline-block"
										custom
									/>
								</CCol>
							</CRow>
						</CCol>
					</CRow>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import { mapState, mapActions, mapGetters } from 'vuex';

import { transformPaymentMethodSelector, transformSelectedPaymentList } from '../assets/js/transform/payments';
import { isIndeterminateCheckbox, isEveryCheckboxChecked, handleCheckboxSelectedAll, removeDuplicateField } from '../assets/js/helpers';

export default {
	name: 'ShippingPaymentMethodSetting',
	props: {
		paymentMethodSettings: {
			type: Array,
			default: () => [],
		},
		title: {
			type: String,
			default: 'Payment method',
		},
	},
	data() {
		return {
			isLoading: true,
			localPaymentMethodList: [],
			isIndeterminateCheckbox,
			isEveryCheckboxChecked,
			handleCheckboxSelectedAll,
		};
	},
	computed: {
		...mapState('payments', {
			paymentMethodlist: (state) => state.shippingPaymentMethods.list,
		}),
		...mapState('shippings', {
			shippingPaymentMethods: 'shippingPaymentMethods',
		}),
		...mapGetters({
			paymentMethodGroupList: 'payments/paymentMethodGroupList',
			paymentMethodGroupDetails: 'payments/paymentMethodGroupDetails',
		}),
	},
	watch: {
		localPaymentMethodList: {
			immediate: false,
			deep: true,
			handler(value) {
				if (!value || value.length <= 0) {
					return;
				}

				const flatPaymentTypeList = value.reduce((acc, cur) => {
					// If there are any methods in group
					// Will ignore parameter of payment group
					const group = isEmpty(cur.methods) && cur.isSelected
						? [
							{
								payment_method_group_id: cur.id,
							},
						]
						: [];

					const methods = cur.methods
						? cur.methods
							// Get only method that have at lease one payment_type
							.filter((method) => (method.isSelected))
							.map((method) => ({
								payment_method_id: method.id,
							}))
						: [];

					return [
						...acc,
						...group,
						...methods,
					];
				}, []);

				this.$emit('update:paymentMethodSettings', flatPaymentTypeList);
			},
		},
	},
	async created() {
		this.loadingShippingPaymentMethodEdit();
		const hasPaymentListData = this.shippingPaymentMethods.list.length > 0;
		if (!hasPaymentListData) {
			await this.getPaymentMethodGroups();
			const groups = [...this.paymentMethodGroupList];

			const groupMethods = await Promise.all(groups.map(async (group) => {
				await this.getPaymentMethodGroup(group.id);
				return this.paymentMethodGroupDetails;
			}));

			// Save to store
			this.setShippingPaymentMethodList(groupMethods);

			const transformedPaymentMethodList = groupMethods.map((method) => {
				return transformPaymentMethodSelector(method, transformSelectedPaymentList, this.paymentMethodSettings);
			});

			this.localPaymentMethodList = removeDuplicateField(transformedPaymentMethodList, 'id');
		} else {
			const transformedPaymentMethodList = this.shippingPaymentMethods.list.map((method) => {
				return transformPaymentMethodSelector(method, transformSelectedPaymentList, this.paymentMethodSettings);
			});

			this.localPaymentMethodList = removeDuplicateField(transformedPaymentMethodList, 'id');
		}

		this.isLoading = false;
		this.loadedShippingPaymentMethodEdit();
	},
	methods: {
		...mapActions({
			getPaymentMethodGroups: 'payments/getPaymentMethodGroups',
			getPaymentMethodGroup: 'payments/getPaymentMethodGroup',
			setShippingPaymentMethodList: 'shippings/setShippingPaymentMethodList',
			loadingShippingPaymentMethodEdit: 'shippings/loadingShippingPaymentMethodEdit',
			loadedShippingPaymentMethodEdit: 'shippings/loadedShippingPaymentMethodEdit',
		}),
		handleCheckAll(isCheck = false) {
			this.localPaymentMethodList = this.handleCheckboxSelectedAll('isSelected', this.localPaymentMethodList, isCheck);
		},
	},
};
</script>

<style lang="scss" scoped>
	.label {
		white-space: nowrap;
	}
</style>
