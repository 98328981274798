<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
		>
			<template #header-wrapper>
				<ModalHeaderWrapper
					:title="title"
					@onClose="close"
				/>
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetStore
							ref="store-form"
							:selected-list="localSelectedList"
							@onChange="handleListChange"
						>
							<template #no-item>
								<div
									class="text-center mt-5 mb-5"
									data-test-id="no-item"
								>
									<div class="typo-body-1 font-weight-bolder color-black-45">
										Search not found
									</div>
									<div class="typo-body-2 color-black-45">
										We didn't find any store matching your criteria
									</div>
								</div>
							</template>
						</FormGetStore>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="localSelectedList.length"
					@onCancel="close"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
		<BaseModalConfirm
			v-if="needConfirmation"
			ref="modal-confirm"
			:title="titleConfirm"
			:description="descriptionConfirm"
			@onConfirm="handleConfirm"
		/>
	</div>
</template>


<script>
import cloneDeep from 'lodash/cloneDeep';
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import FormGetStore from '@/components/FormGetStore.vue';

export default {
	name: 'ModalStore',
	components: {
		ModalHeaderWrapper,
		BaseModalFooter,
		BaseModalConfirm,
		FormGetStore,
	},
	props: {
		title: {
			type: String,
			default: 'Add store',
		},
		titleConfirm: {
			type: String,
			default: null,
		},
		descriptionConfirm: {
			type: String,
			default: null,
		},
		needConfirmation: {
			type: Boolean,
			default: false,
		},
		selectedList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			localSelectedList: [...this.selectedList],
		};
	},
	methods: {
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		submit() {
			this.isShow = false;
			this.$emit('onConfirm', this.localSelectedList);
		},
		resetState() {
			this.localSelectedList = cloneDeep(this.selectedList);
			this.$refs['store-form'].resetState();
		},
		handleListChange(list) {
			this.localSelectedList = list;
		},
		handleSubmit() {
			if (this.needConfirmation) {
				this.$refs['modal-confirm'].open();
			} else {
				this.submit();
			}
		},
		handleConfirm() {
			this.submit();
		},
	},
};
</script>
